import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';


class NotFound404 extends Component {
    render() {
        return (
            <div style={{margin:'50px 0'}}>
                <h1 className="notFound_title">404</h1>
                <p className="notFound_subTitle">Oops, we can't find the page you're looking for.</p>
                <p className="notFound_container">
                    This page doesn't exist or the address is not correct.
                </p>
                <button className="btn_cta color">
                    <NavLink exact to="/">Go back to the home</NavLink>
                </button>
            </div>
        )
    }
}

export default NotFound404