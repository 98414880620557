import React from 'react';


const Column = props => {
    const styles = {
        width: props.width,
        textAlign: props.textAlign,
        display: "inline-block",
        verticalAlign: "top"
    }

    return (
        <div style={styles}>
            {props.children}
        </div>
    );
};

export default Column;