import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from "./ScrollToTop";
import 'intersection-observer';


ReactDOM.render(
    <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ScrollToTop>
            <App/>
        </ScrollToTop>
    </BrowserRouter>
    , document.getElementById('root'));

serviceWorker.unregister();
