import React, { Component }  from 'react';
import Column from './layout/Column';

class CookieBanner extends Component {
    state = {
        isOpen: false
    }

    close = () => {
        this.setState({ isOpen: false })
    }

    render() {
        const { isOpen } = this.state
        return (
            isOpen && (
                <div className="cookieBanner_container">
                    <div className="container">
                        <div className="cookieBanner_header">
                            <i onClick={this.close} className="fas fa-times"></i>
                        </div>
                        <Column width="20%" >
                            <i className="fas fa-cookie-bite cookie"></i>
                        </Column>
                        <Column width="70%" textAlign="left" >
                            <h4>Ce site utilise des cookies</h4>
                            <p>
                                Nous utilisons des cookies pour vous assurer la meilleure expérience.
                                En utilisant notre site web vous accepter <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent"
                            >
                                notre politique de Cookies.
                            </a>
                            </p>
                            <button onClick={this.close} >J'ai compris</button>
                        </Column>
                    </div>
                </div>
            )

        );
    }
};

export default CookieBanner;